<template>
  <div class="text-center permission-denied">
    <div class="denied-image">
      <b-img
        src="/media/images/access-denied.svg"
        class="brand-image"
        alt="Permission Denied"
      />
    </div>
    <div class="text-center">
      <h4>Access Denied</h4>
      <p class="mb-0">You are not authorized to access this page.</p>
      <p class="mb-0">Please contact to administrator.</p>
    </div>
    <!-- // container-fluid px-0 py-0 vh-100 d-flex align-items-center justify-content-center -->
  </div>
</template>

<script>
export default {
  name: "PermissionDenied",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Permission Denied",
  },
  data() {
    return {};
  },
};
</script>
